import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import Interweave from "interweave"


// components
import homePageStyle from "../assets/jss/pale-legs-style/pages/homepageStyle"
import SEO from "../components/Seo/seo"
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Parallax from "../components/Parallax/Parallax"
import Card from "../components/Card/Card"
import CardBody from "../components/Card/CardBody"

const useStyles=makeStyles(homePageStyle);

const Disclaimer=(props)=>{

  const classes = useStyles(props);
  const {bannerImage,bannerTitle,content,description,title,filter}=props.data.allSiteData.edges[0].node.content



  return (
    <div style={{marginTop:70}}>
      <SEO
        title={title}
        description={description}
      />
      <Parallax small image={bannerImage} filter={filter}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem container justify={'center'} alignItems={'center'}>
              <h1>{bannerTitle}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <Card>
          <CardBody>
            <Interweave
              content={content}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}


export const query = graphql`
    {
        allSiteData(filter: {title: {eq: "disclaimerPage"}}) {
            edges {
                node {
                    id
                    content {
                        bannerImage
                        bannerTitle
                        description
                        filter
                        title
                        content
                    }
                }
            }
        }
    }
`

export default Disclaimer;